<template>
  <datatable :columns="columns" :data="cancels">
    <template slot-scope="{ row }">
      <tr>
        <td>
          <router-link
            :to="`/calloff-overview/${row.user_id}`"
            class="text-blue-500 underline text-sm"
          >
            {{ row.user_name }}
          </router-link>
        </td>

        <td>{{ row.past_4_weeks || 0 }}</td>
        <td>{{ row.past_year || 0 }}</td>
      </tr>
    </template>
    <template v-slot:no-results>
      {{ $t("no_data_available_on_table") }}
    </template>
  </datatable>
</template>

<script>
import { get } from "@/services/cancel-service";

export default {
  data: () => ({
    cancels: [],
  }),
  async mounted() {
    this.$emit("setLoading", true);
    try {
      const { data } = await get();
      this.cancels = data;
    } catch (error) {
      console.error("DEBUG: Cancel mounted error", error);
    }
    this.$emit("setLoading", false);
  },
  methods: {},
  computed: {
    columns() {
      return [
        {
          label: this.$i18n.t("calloff_overview_theader_name"),
          field: "user_name",
        },
        {
          label: this.$i18n.t("calloff_overview_theader_closed_last_4_weeks"),
          field: "past_4_weeks",
        },
        {
          label: this.$i18n.t("calloff_overview_theader_called_off_365_days"),
          field: "past_year",
        },
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
table {
  width: 100%;

  &::v-deep th {
    text-align: left !important;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
    padding: 5px 5px 5px 10px;
    min-width: 100px;
    width: 33.33%;
  }

  &::v-deep tbody > tr {
    &:hover,
    &:nth-child(odd) {
      background-color: #f9f9f9;
    }

    td {
      vertical-align: top;
      font-size: 13px;
      padding: 5px 5px 5px 10px;

      &[colspan="3"] {
        text-align: center;
      }
    }
  }
}
</style>
